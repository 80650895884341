import { Box } from '@chakra-ui/react'
import { ChakraProvider } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import URLLayer from 'URLLayer'
import { CopilotPuzzelLayer } from 'copilot/CopilotPuzzelLayer'
import { Mock } from 'copilot/Mock'
import { theme } from 'copilot/theme'
import { PostHogConfig } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { type FC, type ReactNode, StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import PuzzelLayer from './PuzzelLayer'
import TestLayer from './TestLayer'
import CapturiFooter from './components/CapturiFooter'
import CapturiThemeProvider from './components/CapturiThemeProvider'
import { POSTHOG_API_KEY, isDev } from './config'
import { messages as messagesDa } from './locales/da/messages.mjs'
import { messages as messagesEn } from './locales/en/messages.mjs'
import { messages as messagesNo } from './locales/no/messages.mjs'
import { messages as messagesSe } from './locales/se/messages.mjs'
const queryClient = new QueryClient()
const postHogOptions: Partial<PostHogConfig> = {
  api_host: 'https://posthog.capturi.ai',
  disable_session_recording: true,
  request_batching: true,
  autocapture: false,
}

const container = document.getElementById('app-root')

i18n.load({
  da: messagesDa,
  en: messagesEn,
  no: messagesNo,
  se: messagesSe,
})
i18n.activate('en')

//poor mans router
const getCurrentRoute = () => {
  // if (isDev)
  //   return (
  //     <LegacyWrapper>
  //       <TestLayer />
  //     </LegacyWrapper>
  //   )
  //For legacy reasons; there are still som widgets on the puzzel-widget.capturi.ai subdomain
  const isPuzzel = window.location.host.includes('puzzel-widget')
  if (isPuzzel)
    return (
      <LegacyWrapper>
        <PuzzelLayer />
      </LegacyWrapper>
    )

  const path = window.location.pathname.split('/')[1] || ''
  switch (path) {
    case 'demo':
      return (
        <LegacyWrapper>
          <TestLayer />
        </LegacyWrapper>
      )
    case 'puzzel':
      return (
        <LegacyWrapper>
          <PuzzelLayer />
        </LegacyWrapper>
      )
    case 'copilot':
      if (isDev)
        return (
          <CopilotWrapper>
            <Mock />
          </CopilotWrapper>
        )
      return (
        <CopilotWrapper>
          <CopilotPuzzelLayer />
        </CopilotWrapper>
      )
    default:
      return (
        <LegacyWrapper>
          <URLLayer />
        </LegacyWrapper>
      )
  }
}

const LegacyWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <CapturiThemeProvider>
      <Box
        bg="gray.50"
        width="100vw"
        height="calc(100vh - 45px)" //45 is the footer height
        overflow="scroll"
      >
        {children}
      </Box>
      <CapturiFooter />
    </CapturiThemeProvider>
  )
}

const CopilotWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <Box
        position="absolute"
        height={'200px'}
        width={'100%'}
        aria-hidden
        bottom={0}
        zIndex={-1}
        css={css`
          aspect-ratio: 3/2;
          /* update the 85% to control the curvature
            you can also update the 50% to control the position
          */
          clip-path: ellipse(85% 100% at 50% 100%);
          background:linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), radial-gradient(88.67% 177.33% at 103.33% 100%, #33C4B3 0%, rgba(51, 196, 179, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(360deg, rgba(51, 196, 179, 0.25) 0%, rgba(51, 196, 179, 0.5) 66%);
  `}
      />
      {children}
    </ChakraProvider>
  )
}

const enableMocking = async () => {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  const { server } = await import('./copilot/mockServer')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return server.start()
}

enableMocking().then(() => {
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const root = createRoot(container!)
  root.render(
    <StrictMode>
      <PostHogProvider apiKey={POSTHOG_API_KEY} options={postHogOptions}>
        <QueryClientProvider client={queryClient}>
          <I18nProvider i18n={i18n}>{getCurrentRoute()}</I18nProvider>
        </QueryClientProvider>
      </PostHogProvider>
    </StrictMode>,
  )
})
